/* eslint-disable camelcase */
// This was needed for the gtag options, eg ad_storage - using the same thing D provided so in analytics side its uniform

window.dataLayer = window.dataLayer || [];
function gtag() {
    window.dataLayer.push(arguments);
}
gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'granted',
});
function cookieConsentGetCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === `${name}=`) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
function cookieConsentSetCookie(name, value, days) {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1e3);
        expires = `; expires="${date.toUTCString()}`;
    }
    document.cookie = `${name}=${(value || '') + expires}; path=/`;
}

function cookieConsentCookiesAllAccept() {
    cookieConsentSetCookie('cmp_user_consent', '1', 365);
    document.querySelector('.js-cookie-consent').classList.remove('show');
    gtag('consent', 'update', {
        ad_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        analytics_storage: 'granted',
    });
    if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push({ event: 'cookie_consent_accepted' });
    }
}

function cookieConsentCookiesDeny() {
    cookieConsentSetCookie('cmp_user_consent', '1', 1);
    document.querySelector('.js-cookie-consent').classList.remove('show');
    gtag('consent', 'update', {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        analytics_storage: 'granted',
    });
    if (typeof window.dataLayer !== 'undefined') {
        window.dataLayer.push({ event: 'cookie_consent_denied' });
    }
}

function runCookieConsentStart() {
    const consent = cookieConsentGetCookie('cmp_user_consent');
    if (consent !== '1') {
        document.querySelector('.js-cookie-consent').classList.add('show');
        if (typeof window.dataLayer !== 'undefined') {
            window.dataLayer.push({ event: 'cookie_consent_banner_displayed' });
        }
        document
            .querySelector('.js-cookie-consent-deny')
            .addEventListener('click', cookieConsentCookiesDeny);
        document
            .querySelector('.js-cookie-consent-accept')
            .addEventListener('click', cookieConsentCookiesAllAccept);
    }
}

if (document.readyState !== 'loading') {
    runCookieConsentStart();
} else {
    document.addEventListener('DOMContentLoaded', function () {
        runCookieConsentStart();
    });
}
