'use strict';

import DataTransport from "@/helpers/data-transport";
import AlertMessage from "@/helpers/alert-message";

(function() {
    const transport = new DataTransport();
    const alertMessage = new AlertMessage();

    const refundForm = document.querySelector('[data-role="refund-form"]');
    const transactionId = refundForm.dataset.transactionId;

    refundForm.addEventListener('submit', async function(e) {
        e.preventDefault();

        // Get the submit button
        const submitButton = this.querySelector('button[type="submit"]');
        const submitButtonTxt = submitButton.innerHTML;

        alertMessage.HideAlert();

        // Create a FormData object to capture all form inputs
        const formData = new FormData(this);

        submitButton.innerHTML = "Please Wait";
        submitButton.disabled = true;

        await transport.PostFormData(`/admincp/ViewTransaction/?exec=refund&id=${transactionId}`, formData)
            .then(async data => {
                console.log(data);
                if (typeof data === 'object') {
                    if (data.error) {
                        alertMessage.ShowError(data.message);
                        submitButton.innerHTML = submitButtonTxt;
                        submitButton.disabled = false;
                    } else {
                        // success
                        /*
                        alertMessage.ShowSuccess(data.message);
                        submitButton.innerHTML = submitButtonTxt;
                        submitButton.disabled = false;
                         */
                        window.location.href = data.data.url;
                    }
                } else {
                    // Raw Text;
                }
            })
            .catch(error => console.error('Failed to fetch data:', error))
            .finally(() => {

            });
    });
})();