import DataTables from 'datatables.net-bs5';

import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-dt/css/dataTables.dataTables.min.css';

(function() {
    // Select all sections with the datatable import attribute
    const sections = document.querySelectorAll('section[data-js-import="datatable"]');

    // Iterate over each section
    sections.forEach((section) => {
        const tableSelector = section.dataset.table; // Gets the value of `data-table`
        const dataUrl = section.dataset.url;         // Gets the value of `data-url`

        fetch(dataUrl)
            .then((response) => response.json())
            .then((json) => {
                // Initialize DataTable with dynamic columns and server-side processing
                new DataTables(`#${tableSelector}`, {
                    responsive: true,
                    serverSide: true, // Enable server-side processing
                    ajax: {
                        url: dataUrl, // URL for fetching data dynamically
                        type: 'POST', // Use POST for sending parameters to the server
                        dataSrc: 'data', // Map the data key in the JSON response to the table rows
                        data: function (d) {
                            // Include additional data for server-side processing if needed
                            console.log(d); // Inspect the parameters being sent to the server
                        }
                    },
                    pageLength: 25, // Set default number of rows to display
                    columns: json.columns, // Dynamically set column definitions from server response
                });
            })
            .catch((error) => console.error('Error initializing DataTable:', error));
    });
})();
