// Accordion questions handler
const accordionItemHeaders = document.querySelectorAll(
    '.accordion-item-header'
);

accordionItemHeaders.forEach((accordionItemHeader) => {
    accordionItemHeader.addEventListener('click', () => {
        accordionItemHeader.classList.toggle('active');
        const accordionItemBody = accordionItemHeader.nextElementSibling;
        if (accordionItemHeader.classList.contains('active')) {
            accordionItemBody.style.maxHeight = `${accordionItemBody.scrollHeight}px`;

            if (accordionItemHeader.classList.contains('accordion-item-header--mobile')) {
                accordionItemBody.classList.add("active");
                accordionItemBody.style.maxHeight = '3000px';
            }
        } else {
            accordionItemBody.style = '';
        }
    });
});


const tabBtns = document.querySelectorAll('.js-faq-nav');
const tabTargets = document.querySelectorAll('.js-faq-tab');

tabBtns.forEach((element) => {
    element.addEventListener('click', (e) => {
        tabBtns.forEach((tab) => {
            tab.classList.remove('active');
        });
        e.target.classList.add('active');
        tabTargets.forEach((tab) => {
            tab.classList.remove('active');
            if (tab.dataset.tab === e.target.dataset.target) {
                tab.classList.add('active');
            }
        });
    });
});
